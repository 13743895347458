import React from "react";
import { connect } from "react-redux";
import {
  getUserRoleId,
  getClientid,
  getRoleList,
  getUserRole,
  getMenuList,
  getUserList,
  getUserInfo,
} from "redux/selectors";
import { Row, Col } from "antd";
import styled from "@emotion/styled";
import CheckInCheckout from "molecules/CheckInCheckout";
import UserMonthlyReport from "molecules/UserMonthlyReport";
import UserCheckinReport from "molecules/UserCheckinReport";
import NonStaffDashboard from "molecules/NonStaffDashboard";
import UserLeaveReport from "molecules/UserLeaveReport";

const StyledRow = styled(Row)`
  padding-top: 5px;
`;

const MyDashboard = ({ userRoleId, userInfo }) => {
  return (
    <div>
      <>
        <StyledRow gutter={16}>
          {(userInfo?.userid !== "neeru@waila.com.au" ||
            userInfo?.userid !== "nesh@neshnarain.com" ||
            userInfo?.userid !== "ritu.bahal@outlook.com") && (
            <>
              <Col md={24} sm={24} xs={24} xl={12}>
                <Col md={24} sm={24} xs={24} xl={24}>
                  <CheckInCheckout />
                </Col>

                <Col md={24} sm={24} xs={24} xl={24}>
                  <UserMonthlyReport />
                </Col>
              </Col>
              <Col
                style={{ marginBottom: "25px" }}
                md={24}
                sm={24}
                xs={24}
                xl={12}
              >
                <Col className="h-100" md={24} sm={24} xs={24} xl={24}>
                  <UserCheckinReport />
                </Col>
              </Col>
            </>
          )}

          <Col md={24} sm={24} xs={24} xl={24}>
            <Col md={24} sm={24} xs={24} xl={24}>
              <UserLeaveReport />
            </Col>
          </Col>
        </StyledRow>
      </>
      {userRoleId == 1 && <NonStaffDashboard />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  userRoleId: getUserRoleId(state),
  ClientId: getClientid(state),
  roleList: getRoleList(state),
  userRoleType: getUserRole(state),
  menuList: getMenuList(state),
  userList: getUserList(state),
  userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, null)(MyDashboard);
